import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorMarcel as author } from 'data/authors';
import img from 'img/blog/covers/funnels.png';
import img1 from 'img/help/how-do-the-funnels-work/funnels1.png';
import Link from 'components/ui/link';

const Content = () => {
  return (
    <Post>
      <p>
        We’ve just launched a long-awaited feature: Funnels. With LiveSession Funnels, you can track
        sets of actions your users take while visiting your website. See which parts of your
        customers’ purchase path could be improved or increase conversion rates on your subscription
        forms.
      </p>

      <p>
        To create your first funnel, simply open the{' '}
        <Link href="https://app.livesession.io/signin" target="_blank" rel="noopener noreferrer">
          Funnels tab
        </Link>
        :
      </p>
      <img src={img1} alt="Funnels tab" title="LiveSession Funnels tab - click in left menu" />
      <p>
        From here, you can view, edit, duplicate and analyze funnels you already created. When
        creating a funnel, you can use any of the LiveSession filters.{' '}
      </p>

      <p>
        Learn more about managing LiveSession Funnels{' '}
        <Link href="/help/how-do-the-funnels-work/">here</Link>.
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Funnels',
  url: '/blog/funnels/',
  description: `We're launching the next long-awaited feature: LiveSession Funnels! See how to use them to get the most of your UX research.`,
  author,
  img,
  imgSocial: img,
  date: '2021-06-30',
  category: '',
  group: 'updates',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
